<!-- 费用清单 -->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" type="danger" @click="doExport()">
                        <div class="wrap" >
                            <i class="iconfont icon-delete font22"></i>
                            <span class="txt">{{msg('导出当前')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doExportAll()">
                        <div class="wrap" >
                            <i class="iconfont icon-delete font22"></i>
                            <span class="txt">{{msg('导出全部')}}</span>      
                        </div>            
                    </el-link>
                </div>
                <!-- <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <i class="iconfont icon-sousuo font22" ></i>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div> -->
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <!-- <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="60px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('标题')">
                        <el-input v-model="scope.query.title"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('概要')">
                        <el-input v-model="scope.query.overview"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('日期')" label-width="100px">
                        <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getNotices" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition> -->

        <div class="table-wrap">
            <!-- <el-table
                :data="notices"
                border
                show-summary
                style="width: 100%">
                <el-table-column prop="id" label="ID" min-width="80"></el-table-column>
                <el-table-column prop="chuhuo" label="出货时间" min-width="80"></el-table-column>
                <el-table-column prop="username" label="客户账号" min-width="80"></el-table-column>
                <el-table-column prop="owner" label="归属人" min-width="80"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
                <el-table-column prop="code" label="订单编号" min-width="80"></el-table-column>
                <el-table-column prop="expCode" label="查询码" min-width="80"></el-table-column>
                <el-table-column prop="amount" label="订单金额" min-width="80"></el-table-column>
                <el-table-column prop="actualIn" label="实际回款" min-width="80"></el-table-column>
                <el-table-column prop="productName" label="货物品名" min-width="80"></el-table-column>
                <el-table-column prop="weight" label="重量" min-width="80"></el-table-column>
                <el-table-column prop="price" label="单价" min-width="80"></el-table-column>
                <el-table-column prop="fee" label="运费" min-width="80"></el-table-column>
                <el-table-column prop="jiaopai" label="交派费" min-width="80"></el-table-column>
                <el-table-column prop="dabao" label="打包费" min-width="80"></el-table-column>
                <el-table-column prop="zhuanji" label="转寄费" min-width="80"></el-table-column>
                <el-table-column prop="tiedan" label="贴单费" min-width="80"></el-table-column>
                <el-table-column prop="fujia" label="附加费" min-width="80"></el-table-column>
                <el-table-column prop="rate" label="汇率" min-width="80"></el-table-column>
                <el-table-column prop="acount" label="总费用" min-width="80"></el-table-column>
                <el-table-column prop="expCompany" label="配送方式" min-width="80"></el-table-column>
                <el-table-column prop="transport" label="运输方式" min-width="80"></el-table-column>
                <el-table-column prop="transitType" label="收费方式" min-width="80"></el-table-column>
                <el-table-column prop="receiver" label="收件人" min-width="80"></el-table-column>
                <el-table-column prop="receiverTel" label="收件人电话" min-width="80"></el-table-column>
                <el-table-column prop="receiverAddr" label="收件人地址" min-width="80"></el-table-column>
                <el-table-column prop="receiverShop" label="收件人门市编号" min-width="80"></el-table-column>
                <el-table-column prop="pkgCode" label="袋号" min-width="80"></el-table-column>
                <el-table-column prop="pici" label="批次" min-width="80"></el-table-column>
                <el-table-column prop="storeName" label="仓库名" min-width="80"></el-table-column>
                <el-table-column prop="company" label="归属公司" min-width="80"></el-table-column>
                <el-table-column prop="shopName" label="店铺名" min-width="80"></el-table-column>
            </el-table> -->
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border export-config
                show-overflow highlight-hover-row
                show-footer
                :footer-method="footerMethod"
                highlight-current-row highlight-hover-column highlight-current-column
                :data="notices"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import Pagenator from "../../components/Pagination.vue";
import XEUtils from 'xe-utils'
export default {
    name: 'Permission',
    components: {
        CtrlBox: CtrlBox,
        Pagenator:Pagenator,
    },
    mounted(){
        this.initColumns();
        this.init();
    },
    props: {
        id: Number,
        remark:String,
    },
    data(){
        return {
            query:{
                title: null,
                overview: null,
                Date: [], 
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            notices: [],      //通知列表
            columns:[],
           
            dgTitle: '',
            curr: null,
            dglogTitle:"查看",
            currNotice:null,
            currContent:null,
            dialogEditVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 250;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        initColumns(){
            this.columns = [
                { type: 'checkbox', minWidth: 60 },
                {field: "id", title: this.msg("ID"), minWidth: 80, sortable: true},
                {field: "chuhuo", title: this.msg("出货时间"), minWidth: 100,  },
                {field: "username", title: this.msg("客户账号"), minWidth: 100,  },
                {field: "owner", title: this.msg("归属人"), minWidth: 100,  },
                {field: "remark", title: this.msg("备注"), minWidth: 100,  },
                {field: "code", title: this.msg("订单编号"), minWidth: 100,  },
                {field: "expCode", title: this.msg("查询码"), minWidth: 100,  },
                {field: "amount", title: this.msg("订单金额"), minWidth: 100,  },
                {field: "actualIn", title: this.msg("实际回款"), minWidth: 100,  },
                {field: "productName", title: this.msg("货物品名"), minWidth: 100,  },
                {field: "weight", title: this.msg("重量"), minWidth: 100,  },
                {field: "price", title: this.msg("单价"), minWidth: 100,  },
                {field: "fee", title: this.msg("运费"), minWidth: 100,  },
                {field: "jiaopai", title: this.msg("交派费"), minWidth: 100,  },
                {field: "dabao", title: this.msg("打包费"), minWidth: 100,  },
                {field: "zhuanji", title: this.msg("转寄费"), minWidth: 100,  },
                {field: "tiedan", title: this.msg("贴单费"), minWidth: 100,  },
                {field: "fujia", title: this.msg("附加费"), minWidth: 100,  },
                {field: "rate", title: this.msg("汇率"), minWidth: 100,  },
                {field: "acount", title: this.msg("总费用"), minWidth: 100,  },
                {field: "expCompany", title: this.msg("配送方式"), minWidth: 100,  },
                {field: "transport", title: this.msg("运输方式"), minWidth: 100,  },
                {field: "transitType", title: this.msg("收费方式"), minWidth: 100,  },
                {field: "receiver", title: this.msg("收件人"), minWidth: 100,  },
                {field: "receiverTel", title: this.msg("收件人电话"), minWidth: 100,  },
                {field: "receiverAddr", title: this.msg("收件人地址"), minWidth: 100,  },
                {field: "receiverShop", title: this.msg("收件人门市"), minWidth: 100,  },
                {field: "pkgCode", title: this.msg("袋号"), minWidth: 100,  },
                {field: "pici", title: this.msg("批次"), minWidth: 100,  },
                {field: "storeName", title: this.msg("仓库名"), minWidth: 100,  },
                {field: "company", title: this.msg("归属公司"), minWidth: 100,  },
                {field: "shopName", title: this.msg("店铺名"), minWidth: 100,  },
            ]
        },
        init(){
            this.getNotices();
        },
        getNotices(refresh){
            var _this = this;
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            this.query.id = this.id;

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.wallet.listForDetail, params)
                .then((result) => {
                    if(result.status){
                        _this.notices = result.data.list;
                        _this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        // checkRow({row, $event}){
        //     //如果点击checkbox 则不处理
        //     if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
        //         var table =  this.$refs.dataTable;
        //         table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        //     }
        //     if(columnIndex > 0){  //选择 
        //         var table =  this.$refs.dataTable;
        //         table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        //     }
        // },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getNotices();
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        footerMethod ({ columns, data }) {
              const sums = []
              columns.forEach((column, columnIndex) => {
                if (columnIndex === 0) {
                  sums.push('总计')
                } else {
                  let sumCell = null
                  switch (column.property) {
                    case 'actualIn':
                    case 'weight':
                    case 'fee':
                    case 'jiaopai':
                    case 'dabao':
                    case 'zhuanji':
                    case 'tiedan':
                    case 'fujia':
                    case 'acount':
                      sumCell = XEUtils.sum(data, column.property)
                      break
                  }
                  sums.push(sumCell)
                }
              })
              // 返回一个二维数组的表尾合计
              return [ sums ]
        },
        doExportAll(){//导出所有
            let id = this.id;
            this.openWindowWithPost(this.$kit.api.export.exportWalletDetailList,{id:id,name:this.remark});
            // window.open(this.$kit.api.export.exportWalletDetailList+"?id="+id+"&name="+this.remark,"_blank");
        },
        openWindowWithPost(url, data) {
            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = url;
            form.style.display = "none";

            for (var key in data) {
                var input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
