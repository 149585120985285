<!--导入账单明细-->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('导入账单')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row> 
        <el-row :gutter="10"  class="ctrl-wrap">
            <el-col :sm="24" :md="12">
                <el-button size="mini"
                    @click="doSelectFile()" type="success" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="iconfont icon-xuanzewenjian" 
                            style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('上传文件')}}</span>
                        </transition>
                </el-button>
              
                <div ref="fileArea" style="display:none;">
                </div>
                <el-select v-model="companyId" style="margin-bottom:5px;"
                    placeholder="选择结算公司" size="mini">
                    <el-option
                        v-for="item in companys"
                        :key="item.value" 
                        :label="item.label"
                        :value="item.value"> 
                    </el-option>
                </el-select>
            </el-col>  
            <el-col :sm="24" :md="12">
                <el-button size="mini"
                    @click="doDownloadTpl()" type="primary" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('下载模板')}}</span>
                        </transition>
                </el-button>
            </el-col>  
        </el-row>
        <el-row :gutter="15">
            <el-table :data="results" :height="docHeight" style="width: 100%" size="mini" ref="dataTable" stripe>
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="typeLabel" :label="msg('类型')" min-width="120" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="amount" :label="msg('金额')" min-width="100" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="account" :label="msg('账号')" min-width="100" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="msg" :label="msg('上传结果')" min-width="200" :show-overflow-tooltip="true"></el-table-column>
            </el-table>
        </el-row>
    </div>
</template>
<script>
import XLSX from "xlsx";
export default {
    name: 'WalletImport',
    components: {},
    data(){
        return {
            companys:[],
            companyId:null,
            excelHeads: [], //从excel解析出数据的表头
            excelData: null, //从excel解析出的数据
            results:[],
        }
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
    },
    mounted(){
       this.getCompanys();
    },
    methods:{
        doSelectFile(){  //点击选择文件

            if(!this.companyId){
                this.$message(this.msg('请选择结算公司'));
                return;
            }
            
            this.$prompt("请输入结算密码", this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                inputType: 'password',
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.wallet.checkCompanyPwd+"?companyId="+this.companyId+"&pwd="+value)
                    .then((result) => {
                        if(result.status){
                            this.$refs.fileArea.innerHTML = "";
                            var input = document.createElement("input");
                            input.type="file";
                            var _this = this;
                            input.onchange=this.getUploadFile;
                            this.$refs.fileArea.appendChild(input);
                            input.click();
                        }else{
                            this.$message(this.msg('结算密码不正确'));
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        getUploadFile(e) {  //解析上传的excel
            let that = this;

            this.excelData = null
            //拿到所导入文件的名字
            let fileName = e.target.files[0];
            //定义reader，存放文件读取方法
            let reader = new FileReader();
            //启动函数
            reader.readAsBinaryString(fileName)
            //onload在文件被读取时自动触发
            reader.onload = (e) => {
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(e.target.result, {type: 'binary'})
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames
                //存放json数组格式的表格数据
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let json = XLSX.utils.sheet_to_json(worksheet, {header:1})

                var heads = json[0];
                that.excelData = json.splice(1);
                that.excelHeads = heads;
                that.$refs.fileArea.innerHTML = "";
                this.doUpload();
            };
        },
        doDownloadTpl(){  //下载一个excel模板，
           
            var titles = [['类型','金额','账户','说明'],[],[],[],['类型说明：充值、提现、运费结算、回款结算、理赔、其它支出, 客诉件结算']];
 
            var filename = '账单导入模板.xlsx';

            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(titles);

            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, filename);
        },
        doUpload(){

            this.$axios.post(this.$kit.api.wallet.doImport+"?companyId="+this.companyId, this.excelData)
                .then((result) => {
                    if(result.status){
                        result.data.forEach(r => {
                            try{
                                r.typeLabel = this.dicts.walletTypes1[r.type].label;
                                if(r.id){
                                    r.msg = this.msg("成功");
                                }
                                r.amount = (r.amount / 100.0).toFixed(2);
                            }catch(err){
                                console.error(err);
                            }
                        });
                        this.results = result.data;
                    }
                });
        },
        getCompanys(){
            this.$axios.post(this.$kit.api.company.listByDataPath,)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data){
                            this.companys = [];
                            data.forEach(e => {
                                this.companys.push(
                                        {
                                            label:e.name,
                                            value:e.id,
                                        }
                                    )
                            })
                        }
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>

</style>