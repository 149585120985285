<!-- 账单流水-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="viewDetailLists()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-huiyuanzhangdan" font-size="27px;"/>
                            <span class="txt" >{{msg('费用清单')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="walletDetail()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-qianbaoxiangqing" font-size="27px;"/>
                            <span class="txt" >{{msg('钱包详情')}}</span>   
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('wallet:finish')" :underline="false" @click="updateStatus(1)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-caiwushenhe" font-size="27px;"/>
                            <span class="txt">{{msg('账单审核')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:cancel')" :underline="false" @click="updateStatus(2)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-Ttubiao" font-size="27px;"/>
                            <span class="txt">{{msg('取消审核')}}</span>      
                        </div>            
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link v-if="hasPermission('wallet:import')" :underline="false" @click="doTransitImport()">
                        <div class="wrap">
                            <icon-svg icon-class="icon-daoru3" font-size="27px;"/>
                            <span class="txt">{{msg('导入运费')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:import')" :underline="false" @click="doImport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shujudaoru" font-size="27px;"/>
                            <span class="txt">{{msg('导入账单')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:import')" :underline="false" @click="doImportImg()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shangchuanfujian" font-size="27px;"/>
                            <span class="txt">{{msg('上传附件')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:importRemark')" :underline="false" @click="importRemark()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daoruxinxi" font-size="27px;"/>
                            <span class="txt">{{msg('导入说明')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:export')" :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu" font-size="27px;"/>
                            <span class="txt">{{msg('导出账单')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-if="hasPermission('wallet:delete')" :underline="false" @click="deleteDetail()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除账单')}}</span>      
                        </div>            
                    </el-link>  
                    <el-link v-if="hasPermission('wallet:updateRemark1')" :underline="false" @click="updateRemark1()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('更新备注')}}</span>      
                        </div>            
                    </el-link> 
                    <el-link v-if="hasPermission('wallet:updatePayStatus')" :underline="false" @click="updatePayStatus(0)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-yijiesuan" font-size="27px;"/>
                            <span class="txt">{{msg('已结算')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('wallet:updatePayStatus')" :underline="false" @click="updatePayStatus(1)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-weijiesuan" font-size="27px;"/>
                            <span class="txt">{{msg('未结算')}}</span>      
                        </div>            
                    </el-link> 
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item v-if="!scope.query.userId" :label="msg('用户名')">
                        <!-- <el-input v-model="scope.query.name"  :placeholder="msg('账号或者姓名')" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.names" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('流水号')">
                        <!-- <el-input v-model="scope.query.code" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.codes" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('账单状态')">
                        <el-select v-model="scope.query.status" multiple clearable>
                            <el-option
                                v-for="item in dicts.walletStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('付款状态')">
                        <el-select v-model="scope.query.payStatus" multiple clearable>
                            <el-option
                                v-for="item in dicts.walletPayStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('账单类型')" >
                        <el-select v-model="scope.query.type" multiple clearable>
                            <el-option
                                v-for="item in dicts.walletTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('结算公司')">
                        <el-input v-model="scope.query.companyName" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('组别')">
                        <el-input v-model="scope.query.company" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('创建人')">
                        <el-input v-model="scope.query.createName" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('更新人')">
                        <el-input v-model="scope.query.updateName" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('说明')">
                        <el-input v-model="scope.query.remark" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注')">
                        <el-input v-model="scope.query.remark1" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('创建日期')">
                        <el-date-picker
                            v-model="scope.query.Date"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            clearable
                            :range-separator="msg('至')"
                            :start-placeholder="msg('开始日期')"
                            :end-placeholder="msg('结束日期')">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('完成日期')">
                        <el-date-picker
                            v-model="scope.query.Updated"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            clearable
                            :range-separator="msg('至')"
                            :start-placeholder="msg('开始日期')"
                            :end-placeholder="msg('结束日期')">
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getDetails" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" size="mini"
                resizable border export-config
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="details"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog
            :title="msg('导入账单详情')" :fullscreen="isMinScreen"
            :visible.sync="iptVisible"
            :close-on-click-modal="false"
            :before-close="doCloseImport"
            width="70%">
                <wallet-import @close-dg="iptVisible=false"/>
        </el-dialog>

        <el-dialog
            :title="msg('导入说明')" :fullscreen="isMinScreen"
            :visible.sync="iptRemarkVisible"
            :close-on-click-modal="false"
            :before-close="doCloseImport"
            width="70%">
                <wallet-import-remark @close-dg="iptRemarkVisible=false"/>
        </el-dialog>

        <el-dialog :title="detailListUser+'>>'+detailListRemark1" :close-on-click-modal="false" @opened="openedDetailList"
            :visible.sync="dgDetailListVisible" width="95%"
            :fullscreen="isMinScreen">
                <wallet-detail-list :id="userWalletDetailId" :remark="detailListUser+detailListRemark1" ref="detailListRef" @close-dg="closeDetailListDialog" />
        </el-dialog>

        <el-dialog
            :title="msg('导入附件')"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            width="500px">
            <span>
                <el-upload class="avatar-uploader" :action="upload"
                        :show-file-list="false"
                        :with-credentials="true"
                        :on-success="(res) => handUploadSuccess(res)"
                    >
                    <el-image
                        v-if="this.attachmentId!=0"
                        style="width: 200px; height: 200px" :src="imgUrl+this.attachmentId"
                        :preview-src-list="[imgUrl+this.attachmentId]"
                        fit="contain">
                    </el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doSaveWalletDetailImg()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

    </div>
   
</template>

<script>
/* eslint-disable */　
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import WalletImport from "./WalletImport.vue";
import WalletImportRemark from "./WalletImportRemark.vue";
import WalletDetailList from "./WalletDetailList.vue";
export default {
    name: 'WalletDetail',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        WalletImport:WalletImport,
        WalletImportRemark:WalletImportRemark,
        WalletDetailList:WalletDetailList
    },
    mounted(){
        this.query.userId = this.userId;
        this.loginUserId = this.$store.state.loginId;
        this.buildColumns();
        this.getDetails();
    },
    props: {
        userId: Number,
    },
    data(){
        return {
            query:{
                code: null,
                codes:null,
                name: null,
                names:null,
                companyName:null,
                company:null,
                createName:null,
                updateName:null,
                remark:null,
                remark1:null,
                status: [],
                payStatus: [],
                userId: null,
                type: [],
                Date: [],
                Updated:[],
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },
            loginUserId: null,

            showQuery: false,
            iptVisible: false,
            iptRemarkVisible: false,
            dgDetailListVisible:false,
            userWalletDetailId:null,
            detailListUser:null,
            detailListRemark1:null,

            upload: this.$kit.api.upload+"?type=stock/wallet&auth=1",
            imgUrl: this.$kit.api.imgView,
            dialogVisible:false,//附件弹窗
            attachmentId:0,

            columns: [],
            details: [],      //流水列表

            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        buildColumns(){
            this.columns = [
                { type: 'checkbox', minWidth: 30, sortable:true },
                {field: "id", title: this.msg("ID"), minWidth: 50, sortable:true},
                {field: "created", title: this.msg("创建时间"), minWidth: 120, sortable:true},
                {field: "companyName", title: this.msg("结算公司"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "ownerName", title: this.msg("姓名"), minWidth: 100, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "remark1", title: this.msg("备注"), minWidth: 160, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "ownerAccount", title: this.msg("账号"), minWidth: 100, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "status", title: this.msg("账单状态"), minWidth: 120, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.walletStatus.find(d => d.value === row.status);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
                            ];
                        },
                    },
                    formatter : this.formatStatus
                },
                {field: "type", title: this.msg("账单类型"), minWidth: 120, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let type = this.dicts.walletTypes.find(d => d.value === row.type);
                            return [
                                <el-tag type={type?type.type:''}>{type?type.label:row.type}</el-tag>
                            ];
                        },
                    },
                    formatter :this.formatType
                },
                {field: "payStatus", title: this.msg("付款状态"), minWidth: 110, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.walletPayStatus.find(d => d.value === row.payStatus);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.payStatus}</el-tag>
                            ];
                        },
                    },
                    formatter : this.formatStatus
                },
                {field: "amount", title: this.msg("金额"), minWidth: 100, sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let amount = row.amount/100.0;
                            amount = amount.toFixed(2);
                            return [
                                <el-tag type={amount>=0?'success':'danger'}>{amount}</el-tag>
                            ];
                        }
                    },
                    formatter : this.formatAmount
                },
                {field: "income", title: this.msg("实收"), minWidth: 100, sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let income = row.income/100.0;
                            income = income.toFixed(2);
                            return [
                                <el-tag type={income>=0?'success':'danger'}>{income}</el-tag>
                            ];
                        }
                    },
                    formatter : this.formatAmount
                },
                {field: "arrears", title: this.msg("欠款"), minWidth: 100, sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let arrears = row.arrears/100.0;
                            arrears = arrears.toFixed(2);
                            return [
                                <el-tag type={arrears>=0?'success':'danger'}>{arrears}</el-tag>
                            ];
                        }
                    },
                    formatter : this.formatAmount
                },
                {field: 'attachmentId', title: this.msg('附件'), minWidth: 100, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let thumb = row['attachmentId']
                            return thumb?[
                                <el-image  style="width: 60px; height: 60px" src={this.$kit.api.imgView+thumb} preview-src-list={[this.$kit.api.imgView+thumb]}></el-image>
                            ]: [thumb];
                        },
                    }
                },
                {field: "userTag", title: this.msg("组别"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "code", title: this.msg("流水号"), minWidth: 135, sortable:true},
                {field: "createrName", title: this.msg("创建人"), minWidth: 100, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "updated", title: this.msg("完成时间"), minWidth: 100, sortable:true},
                {field: "updaterName", title: this.msg("更新人"), minWidth: 100, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "remark", title: this.msg("说明"), minWidth: 160, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
            ]
        },
        formatStatus({ cellValue }){
            let status = this.dicts.walletStatus.find(d => d.value === cellValue);
            return status.label || cellValue;
        },
        formatAmount({ cellValue }){
            let amount = cellValue/100.0;
            return amount.toFixed(2);
        },
        formatType({ cellValue }){
            let type = this.dicts.walletTypes.find(d => d.value ===cellValue);
            return type.label || cellValue;
        },
        getDetails(refresh){ 

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            if(this.query.names){
                let cs = this.query.names.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query.name = css.join(",");
            }else{
                this.query.name = null;
            }
            if(this.query.codes){
                let cs = this.query.codes.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query.code = css.join(",");
            }else{
                this.query.code = null;
            }
            let selectStatus = [];
            if(this.query.status){
                selectStatus = this.query.status;
                this.query.status = this.query.status.join(',');
            }
            let selectTypes = [];
            if(this.query.type){
                selectTypes = this.query.type;
                this.query.type = this.query.type.join(',');
            }
            let selectPayStatus = [];
            if(this.query.payStatus){
                selectPayStatus = this.query.payStatus;
                this.query.payStatus = this.query.payStatus.join(',');
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.wallet.details, params)
                .then((result) => {
                    if(result.status){
                        this.details = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                    this.query.status = selectStatus;
                    this.query.type = selectTypes;
                    this.query.payStatus = selectPayStatus;
                });
        },
        reset(){
            this.query.name = null;
            this.query.code = null;
            this.query.names = null;
            this.query.codes = null;
            this.query.status = null;
            this.query.type = [];
            this.query.Date = [];
            this.query.Updated = [];
            this.query.start = 0;
            this.query.company = null;
            this.query.createName = null;
            this.query.updateName = null;
            this.query.remark = null;
            this.query.remark1 = null;
            this.query.payStatus = [];
            this.query.userId = this.userId || null;
            // this.query.userId = null;
            this.query.companyName = null;
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getDetails();
        },
        //查看详情
        viewDetail(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let checked = true;
            let prevType = selectes[0].type;
            for(let i=0; i<selectes.length; i++){

                let d = selectes[i];
                let type = d.type;

                if(prevType != type){
                    checked = false;
                    this.$message(this.msg('请选择同一类型的单号'));
                    break;
                }

                if(type != 3 && type !=4 && type != 5){
                    checked = false;
                    this.$message(this.msg('仅支持运费、回款、理赔账单的详情查看'));
                    break;
                }

            }

            if(checked){
                //跳转
                let codes = selectes.map(c => c.code);
                let para = {codes: codes.join(","), type: prevType};
                // console.log(para);
                this.$router.push({path: "/admin/stock", query: para});
            }

        },
        //账单详情
        viewDetailLists(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.userWalletDetailId = selectes[0].id;
            this.detailListUser = selectes[0].ownerName;
            this.detailListRemark1 = selectes[0].remark1;
            this.dgDetailListVisible = true;
        },
        closeDetailListDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallets();
            }
            this.dgDetailListVisible = false;
        },
        //导入
        doImport(){
            this.iptVisible = true;
        },
        //导入运费
        doTransitImport(){
            this.$tabs.open ("/admin/transit/import");//导入运费
        },
        importRemark(){
            this.iptRemarkVisible = true;
        },
        doCloseImport(done){
            this.getDetails();
            done();
        },
        doImportImg(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ok = true;
            let companyId = selectes[0].companyId;
            selectes.forEach(e=>{
                if(e.companyId != companyId){
                    ok = false;
                }
            })

            if(!ok){
                this.$message(this.msg('只能选择同一个结算公司'));
                return;
            }

            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            this.attachmentId = 0;
                            this.dialogVisible = true;
                        }
                    }
                });
        },
        handUploadSuccess(res){
            if(res.status){
                //保存图片ID
                this.attachmentId = res.data;
                this.$message("上传成功");
            }else{
                this.$message("上传失败");
            }
        },
        doSaveWalletDetailImg(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let ids = selectes.map(e => e.id).join(",");
            this.$axios.post(this.$kit.api.wallet.updateWalletDetailImg, {ids:ids,attachmentId:this.attachmentId})
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        this.getDetails();
                        this.dialogVisible = false;
                    }
                });
        },
        //更新状态
        updateStatus(type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.id).join(",");
            let types = selectes.map(e => e.type).join(",");

            let ok = true;
            let companyId = selectes[0].companyId;

            selectes.forEach(e=>{
                if(e.companyId != companyId){
                    ok = false;
                }
            })

            if(!ok){
                this.$message(this.msg('只能选择同一个结算公司的数据'));
                return;
            }

            let hasPer = false;
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{
                            if(type == 1){

                                let checkWord = false;
                                if(types.indexOf('0') > -1 || types.indexOf('1') > -1 || types.indexOf('2') > -1 || types.indexOf('4') > -1 
                                || types.indexOf('5') > -1 || types.indexOf('6') > -1){
                                    checkWord = true;
                                }

                                this.$prompt(this.msg('请输入<'+selectes[0].companyName+'>结算密码'),this.msg('确认密码：'), {
                                    confirmButtonText: this.msg('确定'),
                                    cancelButtonText: this.msg('取消'),
                                    inputType: 'password',
                                }).then(({ value }) => {

                                    this.checkCompanyPwd(value,type,checkWord);

                                }).catch(() => {
                                    console.log("取消操作");         
                                })

                            }else {

                                this.$prompt(this.msg('请输入原因'), this.msg('确定要将选中的数据处理为已取消'), {
                                    confirmButtonText: this.msg('确定'),
                                    cancelButtonText: this.msg('取消'),
                                    inputPattern: /.+/,
                                    inputErrorMessage: this.msg('备注不能为空')
                                }).then(({ value }) => {
                                    this.doUpdateStatus(type, ids, value);
                                }).catch(() => {
                                    console.log("取消操作");         
                                });
                            }
                        }
                    }
                });

        },
        checkCompanyPwd(value,type,checkWord){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let ids = selectes.map(e => e.id).join(",");
            this.$axios.post(this.$kit.api.wallet.checkCompanyPwd+"?companyId="+selectes[0].companyId+"&pwd="+value)
                .then((result) => {
                    if(result.status){

                        if(checkWord){
                            this.checkCompanyWord(type);
                        }else{
                            this.$prompt(this.msg('请输入说明'), this.msg('确定要将选中的数据处理为已完成？'), {
                                confirmButtonText: this.msg('确定'),
                                cancelButtonText: this.msg('取消'),
                            }).then(({ value1 }) => {
                                this.doUpdateStatus(type, ids, value1);
                            }).catch(() => {
                                console.log("取消操作");
                            });
                        }

                    }else{
                        this.$message(result.msg || this.msg('密码不正确'));
                    }
            });
        },
        checkCompanyWord(type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let ids = selectes.map(e => e.id).join(",");
            this.$prompt('请输入<'+selectes[0].companyName+'>口令',this.msg('确认口令：'), {
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                    inputType: 'password',
                }).then(({ value }) => {

                    this.doCheckCompanyWord(value,type);

                }).catch(() => {
                    console.log("取消操作");         
                })
        },
        doCheckCompanyWord(value,type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let ids = selectes.map(e => e.id).join(",");
            this.$axios.post(this.$kit.api.wallet.checkCompanyWord+"?companyId="+selectes[0].companyId+"&pwd="+value)
                .then((result) => {
                    if(result.status){
                        this.$prompt(this.msg('请输入说明'), this.msg('确定要将选中的数据处理为已完成？'), {
                            confirmButtonText: this.msg('确定'),
                            cancelButtonText: this.msg('取消'),
                        }).then(({ value1 }) => {
                            this.doUpdateStatus(type, ids, value1);
                        }).catch(() => {
                            console.log("取消操作");
                        });
                    }else{
                        this.$message(result.msg || this.msg('口令不正确'));
                    }
            });
        },
        updateRemark1(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ok = true;
            let companyId = selectes[0].companyId;

            selectes.forEach(e=>{
                if(e.companyId != companyId){
                    ok = false;
                }
            })

            if(!ok){
                this.$message(this.msg('只能选择同一个结算公司的数据'));
                return;
            }

            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{

                            let ids = selectes.map(e => e.id).join(",");
                            this.$prompt(this.msg('请输入备注信息'), this.msg('更新备注'), {
                                    confirmButtonText: this.msg('确定'),
                                    cancelButtonText: this.msg('取消'),
                                    inputPattern: /.+/,
                                    inputErrorMessage: this.msg('备注不能为空')
                                }).then(({ value }) => {
                                     this.doUpdateRemark1(ids, value);
                                }).catch(() => {
                                    console.log("取消操作");         
                                });

                        }
                    }
                });

        },
        doUpdateRemark1(ids, value){
            this.$axios.post(this.$kit.api.wallet.updateRemark1, {ids:ids,remark1:value})
                .then((result) => {
                    this.$message(result.msg);
                    this.getDetails();
                });
        },
        updatePayStatus(type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ok1 = true;
            let companyId = selectes[0].companyId;

            selectes.forEach(e=>{
                if(e.companyId != companyId){
                    ok1 = false;
                }
            })

            if(!ok1){
                this.$message(this.msg('只能选择同一个结算公司的数据'));
                return;
            }
            this.$axios.post(this.$kit.api.wallet.getOwnerCompanyIds)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        if(data.indexOf(companyId) < 0){
                            this.$message(this.msg('没有开户公司的权限！'));
                            return;
                        }else{

                            let ids = selectes.map(e => e.id).join(",");
                            let ok = true;
                            let msg = "";
                            selectes.forEach(e => {
                                if(type == 0){//已结算
                                    if(e.payStatus != 2){
                                        ok = false;
                                        msg = "已结算数据必须全部为已付状态";
                                    }
                                }else{//未结算
                                    if(e.payStatus != 3){
                                        ok = false;
                                        msg = "未结算数据必须全部为已结算状态";
                                    }
                                }
                            })
                            if(!ok){
                                this.$message(this.msg(msg));
                                return;
                            }
                            let confirmMsg = "";
                            if(type == 0){
                                confirmMsg = "是否将选中数据更新为已结算？";
                            }else{
                                confirmMsg = "是否将选中数据更新为已付？";
                            }
                            this.$confirm(this.msg(confirmMsg), '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(() => {
                                        this.$axios.post(this.$kit.api.wallet.updatePayStatus, {ids:ids,type:type})
                                        .then((result) => {
                                            this.$message(result.msg);
                                            this.getDetails();
                                        });
                                }).catch(() => {
                                    this.$message({
                                        type: 'info',
                                        message: '取消操作'
                                    });          
                                });

                        }
                    }
                });

        },
        deleteDetail(){//删除账单
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            // if(selectes.length > 1){
            //     this.$message(this.msg('请逐条删除'));
            //     return;
            // }
            let isOk = true;
            selectes.forEach(e => {
                if(e.status == 1){
                    isOk = false;
                }
            });
            if(!isOk){
                this.$message(this.msg('已完成账单不能删除'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");

            this.$axios.post(this.$kit.api.wallet.findStocks, {ids:ids})
                .then((result) => {
                    if(result.status){
                        this.$confirm('此流水已保存到系统订单, 是否确认删除本条流水?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                             this.$axios.post(this.$kit.api.wallet.delete, {ids:ids})
                                .then((result) => {
                                    this.$message(result.msg);
                                    this.getDetails();
                                });
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '取消操作'
                            });          
                        });
                    }else{
                        this.doDelete(ids);
                    }
                });

        },
        doDelete(ids){
            this.$confirm('此操作将删除账单明细, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.wallet.delete, {ids:ids})
                .then((result) => {
                    this.$message(result.msg);
                    this.getDetails();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },
        doUpdateStatus(type, ids, remark){
            let url = null;
            let data = {ids: ids, remark: remark}
            if(type == 1){
                url = this.$kit.api.wallet.finish;
            }else{
                url = this.$kit.api.wallet.cancel;
            }

            this.$axios.post(url, data).then((result) => {
                this.$message({
                    dangerouslyUseHTMLString: true,
                    message: result.data ? result.data.join("<br/>") : result.msg
                });
                this.$emit("refresh-wallet");
                this.getDetails();
            });
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        openedDetailList(){
            this.$nextTick(() => {
                this.$refs.detailListRef.init();
            });
        },
        walletDetail(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$router.push("/admin/my/wallet/"+selectes[0].walletUserId);
        },
    }
    
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #555555;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
